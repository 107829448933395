import React from "react";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Container,
    useColorModeValue,
} from "@chakra-ui/react";
import PageTitle from "../components/PageTitle";
import expertiseData from "../assets/json/expertise.json";

function Expertise() {
    const bg = useColorModeValue("light.bg.secondary", "dark.bg.secondary");
    return (
        <Box py={6}>
            <Container maxW="container.lg">
                <PageTitle title="Our Expertise" />
                <Accordion
                    defaultIndex={[0]}
                    allowMultiple
                >
                    {expertiseData.map((item, index) => (
                        <AccordionItem key={index}>
                            <AccordionButton _expanded={{ bg }}>
                                <Box
                                    as="h2"
                                    flex="1"
                                    textAlign="left"
                                    fontWeight={700}
                                >
                                    {item.label}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4} textAlign="justify">{item.description}</AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </Container>
        </Box>
    );
}

export default Expertise;
