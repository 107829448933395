import React from "react";
import { Image, useColorModeValue } from "@chakra-ui/react";

const logoLight = require("../assets/images/logo-light.png");
const logoDark = require("../assets/images/logo-dark.png");

function Logo() {
    return (
        <Image
            h="8"
            src={useColorModeValue(logoDark, logoLight)}
        />
    );
}

export default Logo;
