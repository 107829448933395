import React from "react";
import { Box, Container, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { FaLocationArrow } from "react-icons/fa";
import PageTitle from "../components/PageTitle";
import contactData from "../assets/json/contact.json";

function Contact() {
    return (
        <Box
            py={6}
            bg={useColorModeValue("light.bg.primary", "dark.bg.primary")}
        >
            <Container maxW="container.lg">
                <PageTitle title="Contact Us" />
                <Flex
                    direction="column"
                    mb={6}
                    pb={6}
                    borderBottom={1}
                    borderStyle={"solid"}
                    borderColor={useColorModeValue("light.border.primary", "dark.border.primary")}
                >
                    <Box>
                        <EmailIcon
                            w={6}
                            h={6}
                            mr={4}
                        />
                        <a href="mailto:contact@salvuslawassociates.com">contact@salvuslawassociates.com</a>
                    </Box>
                    <Box mt={3}>
                        <PhoneIcon
                            w={6}
                            h={6}
                            mr={4}
                        />
                        <a href="tel:9810541666">+91 98105 41666</a>
                    </Box>
                </Flex>
                <Flex
                    align={{ base: "start", md: "center" }}
                    direction={{ base: "column", md: "row" }}
                >
                    <Flex
                        align={"center"}
                        mr={{ base: 0, md: 6 }}
                        mb={{ base: 6, md: 0 }}
                    >
                        <Icon
                            w={6}
                            h={6}
                            mr={4}
                            as={FaLocationArrow}
                        />
                        <Box>
                            {contactData.map((item, index) =>
                                <Box key={index} mb={6}>
                                    {item.map((innerItem, innerIndex) =>
                                        <Text key={innerIndex} whiteSpace={"nowrap"}>{innerItem}</Text>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Flex>
                    <iframe
                        title="Office Location"
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3500.840951191397!2d77.21584391508325!3d28.664480582405364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM5JzUyLjEiTiA3N8KwMTMnMDQuOSJF!5e0!3m2!1sen!2sin!4v1683391103974!5m2!1sen!2sin"
                        style={{
                            border: "1px solid #A0AEC0",
                            borderRadius: "5px",
                            display: "flex",
                            flexGrow: 1,
                            width: "100%",
                            minHeight: "400px",
                        }}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </Flex>
            </Container>
        </Box>
    );
}

export default Contact;
