import React from "react";
import { Box, Container, Flex, Heading, Image, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import PageTitle from "../components/PageTitle";
import teamData from "../assets/json/about.json";
const team0 = require("../assets/images/team-0.jpg");
const team1 = require("../assets/images/team-1.jpg");
const team2 = require("../assets/images/team-2.jpg");
const team3 = require("../assets/images/team-3.jpg");
const team4 = require("../assets/images/team-4.jpg");
const team5 = require("../assets/images/team-5.jpg");
const team6 = require("../assets/images/team-6.jpg");

const images = [team0, team1, team2, team3, team4, team5, team6];

function About() {
    const { colorMode } = useColorMode();
    const bgColor = colorMode === "light" ? "light.bg.secondary" : "dark.bg.secondary";
    const textColor = useColorModeValue("light.text.secondary", "dark.text.secondary");
    return (
        <Box pt={6}>
            <Container maxW="container.lg">
                <PageTitle title="About Us" />
                <Text
                    mb={4}
                    textAlign="justify"
                >
                    Founded in 2008, Salvus law Associates is a team comprising of advocates and legal consultants of
                    every age group who are keen on lawyering their arguments. We have a love for our profession and are
                    determined to provide best legal solutions to our clients and are devoted towards our clients' best
                    interests. We at Salvus Law Associates, anticipate, shape and deliver our clients the greatest
                    possible outcome and are committed towards achieving the same. We, as a firm, have acquired
                    specialization in all spheres of legal field and boast about serving 2500+ clients collectively,
                    till date. Salvus Law Associates is a one stop legal chamber for your legal queries and solutions
                    helping you to find a way through.
                </Text>
            </Container>
            {teamData.map((item, index) => {
                const lastIndex = index === teamData.length - 1;
                const isEven = index % 2 === 0;
                const bg = isEven ? bgColor : "";
                const clipPath = isEven
                    ? {
                          base: `polygon(0 0, 100% 5%, 100% ${lastIndex ? "100%" : "95%"}, 0% 100%)`,
                          md: `polygon(0 0, 100% 10%, 100% ${lastIndex ? "100%" : "90%"}, 0% 100%)`,
                      }
                    : {}; // check last index
                const ml_base = isEven ? "" : "auto";
                const ml_sm = isEven ? 0 : 6;
                const textAlign = isEven ? "left" : "right";
                const direction_sm = isEven ? "row" : "row-reverse";
                const mr_sm = isEven ? 6 : 0;
                const py = isEven ? 16 : 10;
                return (
                    <Flex
                        key={index}
                        direction={{ base: "column", sm: "row" }}
                        py={py}
                        mt={index === 0 ? 6 : 0}
                        align={"center"}
                        bg={bg}
                        clipPath={clipPath}
                    >
                        <Container
                            maxW="container.lg"
                            textAlign={textAlign}
                        >
                            <Flex direction={{ base: "column", sm: direction_sm }}>
                                <Image
                                    ml={{ base: ml_base, sm: ml_sm }}
                                    rounded="md"
                                    src={images[index]}
                                    w={48}
                                    h={48}
                                    mr={{ base: 0, sm: mr_sm }}
                                    mb={{ base: 6, sm: 0 }}
                                />
                                <Box>
                                    <Heading
                                        as="h2"
                                        size="md"
                                        mb={1}
                                    >
                                        {item.name}
                                    </Heading>
                                    <Heading
                                        as="h3"
                                        size="md"
                                        color={textColor}
                                        mb={4}
                                    >
                                        {item.post}
                                    </Heading>
                                    <Text textAlign="justify">{item.about}</Text>
                                </Box>
                            </Flex>
                        </Container>
                    </Flex>
                );
            })}
        </Box>
    );
}

export default About;
