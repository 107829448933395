import React from "react";
import { Routes, Route } from "react-router-dom";
import { ChakraProvider, Box, extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import colors from "./assets/json/colors.json";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Disclaimer from "./components/Disclaimer";
import Expertise from "./pages/Expertise";

const theme = extendTheme({
    styles: {
        global: (props) => ({
            body: {
                font: "Lato",
                bg: mode("#EDF2F7", "#2D3748")(props),
                color: mode("#2D3748", "#E2E8F0")(props),
                lineHeight: "base",
            },
        }),
    },
    fonts: {
        body: "Lato",
        heading: "Lato",
    },
    colors,
});

function App() {
    return (
        <ChakraProvider theme={theme}>
            <Box>
                <NavBar />
                <Routes>
                    <Route
                        path="/"
                        element={<Home />}
                    />
                    <Route
                        path="about"
                        element={<About />}
                    />
                    <Route
                        path="expertise"
                        element={<Expertise />}
                    />
                    <Route
                        path="contact"
                        element={<Contact />}
                    />
                </Routes>
                <Disclaimer />
            </Box>
        </ChakraProvider>
    );
}

export default App;
