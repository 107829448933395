import React from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalOverlay,
    useDisclosure,
    Text,
    OrderedList,
    ListItem,
} from "@chakra-ui/react";

function Disclaimer() {
    const isAgreed = localStorage.getItem("agreed");

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: isAgreed !== "true" });

    const agree = () => {
        localStorage.setItem("agreed", "true");
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            isCentered
            scrollBehavior="inside"
            closeOnEsc={false}
            closeOnOverlayClick={false}
        >
            <ModalOverlay backdropFilter={"blur(16px)"} />
            <ModalContent>
                <ModalHeader>Disclaimer</ModalHeader>
                <ModalBody>
                    <Text>
                        The Bar Council of India's current guidelines place restrictions on establishing a website and
                        do not allow lawyers to disclose information about their fields of practise. As a result, Salvus
                        Law Associates is unable to provide any additional information on this website.
                    </Text>
                    <Text>
                        The Bar Council of India has guidelines that prevent law firms from soliciting work or
                        advertising in any way. By selecting 'I AGREE,' the user agrees to the following:
                    </Text>
                    <OrderedList mt={6}>
                        <ListItem>
                            That the user wishes to learn more about Salvus law associates, its practise areas, and its
                            attorneys, for her/his own personal information and use.
                        </ListItem>
                        <ListItem>
                            The information is only made available/provided to the user at her/his particular request,
                            and any information or material downloaded from this website is entirely at the user's
                            discretion, as is any transmission or reception of the information.
                        </ListItem>
                        <ListItem>
                            None of the information on the website is intended to be construed as legal advice or in the
                            nature of a legal opinion.
                        </ListItem>
                        <ListItem>
                            Salvus Law Associates is not responsible for any actions taken by the user as a result of
                            the material/information supplied on this website. If the user has any legal concerns, he or
                            she must always obtain independent legal guidance.
                        </ListItem>
                    </OrderedList>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={agree}>I Agree</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default Disclaimer;
