import React from "react";
import { Heading } from "@chakra-ui/react";

function PageTitle({ title }) {
    return (
        <Heading
            as="h1"
            size="2xl"
            mb={6}
        >
            {title}
        </Heading>
    );
}

export default PageTitle;
