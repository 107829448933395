import React from "react";
import { Box, Container, Flex, Heading, Image, Text, useColorModeValue } from "@chakra-ui/react";

const statueLight = require("../assets/images/statue-light.png");
const statueDark = require("../assets/images/statue-dark.png");

function Home() {
    return (
        <Box pb={6}>
            <Box
                mb={6}
                pt={6}
                pb={16}
                bg={useColorModeValue("light.bg.highlighted", "dark.bg.highlighted")}
                clipPath="polygon(100% 0, 100% 100%, 50% 92%, 0 100%, 0 0)"
            >
                <Flex
                    direction={"column"}
                    align={"center"}
                >
                    <Image
                        maxW={{ base: "35vh", md: "50vh" }}
                        src={useColorModeValue(statueDark, statueLight)}
                    />
                    <Flex mt="6">
                        <Flex
                            h={{ base: "3.5", md: "4" }}
                            w="30vw"
                            ml="10vw"
                            mr="10vw"
                            bg={useColorModeValue("black", "white")}
                            borderRadius={10}
                        ></Flex>
                        <Flex
                            h={{ base: "3.5", md: "4" }}
                            w="30vw"
                            ml="10vw"
                            mr="10vw"
                            bg={useColorModeValue("black", "white")}
                            borderRadius={10}
                        ></Flex>
                    </Flex>
                    <Heading
                        as="h1"
                        textAlign={"center"}
                        lineHeight={1.2}
                        fontSize={{ base: "5xl", md: "6xl", lg: "7xl" }}
                        px={8}
                        fontWeight={700}
                        mt={6}
                        color={useColorModeValue("black", "white")}
                    >
                        Salvus Law Associates
                    </Heading>
                </Flex>
            </Box>
            <Container maxW="container.lg">
                <Text
                    mb={4}
                    lineHeight={1.75}
                    textAlign="justify"
                >
                    Founded in 2008, Salvus law Associates is a team comprising of advocates and legal consultants of
                    every age group who are keen on lawyering their arguments. We have a love for our profession and are
                    determined to provide best legal solutions to our clients and are devoted towards our clients' best
                    interests. We at Salvus Law Associates, anticipate, shape and deliver our clients the greatest
                    possible outcome and are committed towards achieving the same. We, as a firm, have acquired
                    specialization in all spheres of legal field and boast about serving 2500+ clients collectively,
                    till date. Salvus Law Associates is a one stop legal chamber for your legal queries and solutions
                    helping you to find a way through.
                </Text>
            </Container>
        </Box>
    );
}

export default Home;
