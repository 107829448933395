import React, { useEffect } from "react";
import { useColorMode, useColorModeValue, IconButton } from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";
import colors from "../assets/json/colors.json";

function ColorModeSwitcher(props) {
    const { toggleColorMode } = useColorMode();
    const themeColor = useColorModeValue(colors.light.bg.highlighted, colors.dark.bg.highlighted);
    const text = useColorModeValue("dark", "light");
    const SwitchIcon = useColorModeValue(FaMoon, FaSun);

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', themeColor);
    }, [themeColor]);

    const onToggle = () => {
        toggleColorMode();
        document.querySelector('meta[name="theme-color"]').setAttribute('content', themeColor);
    }

    return (
        <IconButton
            size="md"
            fontSize="lg"
            aria-label={`Switch to ${text} mode`}
            variant="ghost"
            color="current"
            marginLeft="2"
            onClick={onToggle}
            icon={<SwitchIcon />}
            {...props}
        />
    );
};

export default ColorModeSwitcher;
